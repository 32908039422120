import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      {/* <!---footer---> */}
      <footer className="mainfooter" role="contentinfo">
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                We provide a platform to select and manage the PC parts at once and providing you with the best possible setup and getting assured with the compatibility.
              </div>
              <div className="col-md-4 col-sm-6">
                {/* <!--Column1--> */}
                <div className="footer-pad">
                  <h4 className="ml-5 text-bold">Useful Links</h4>
                  <ul className="list-unstyled ml-5 font-italic">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="#">Forum</Link>
                    </li>
                    <li>
                      <Link to="#">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="/affiliate">Affiliate Disclaimer</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <h4 className="text-bold">Follow Us</h4>
                <ul className="social-network social-circle">
                  <li>
                    <Link to="#" className="icoFacebook" title="Facebook">
                      <i className="ri-facebook-fill"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="icoLinkedin" title="Linkedin">
                      <i className="ri-linkedin-fill"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 copy">
                <p className="text-center">
                  &copy; PC Builder is an affiliate of the Amazon Associates Program, through which we earn advertising commission on qualified sales by linking it to Amazon products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer --> */}
    </div>
  );
}

export default Footer;
