import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove,handleScroll } from "./BuilderDefault";

function RAM() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                RAM
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.ram ? " d-none" : ""
                    )}
                >
                    <a style={{ textDecoration: "none" }} href="/component/ram" onClick={handleScroll}>
                        + Component
                    </a>
                </button>
                {appState.component.ram ? (
                    <img
                        src={appState.component.ram.image}
                        className="partimg"
                        alt="ram"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.ram ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">

                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.ram.product_id }>
                                    {appState.component.ram
                                        ? appState.component.ram.name
                                        : null}
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.ram.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.ram.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.ram.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.ram.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.ram.quantity
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Quantity: </span>
                                <span id="specs">
                                    {appState.component.ram.quantity}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.ram.ram_type
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">RAM Type: </span>
                                <span id="specs">
                                    {appState.component.ram.ram_type}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.ram.ram_size
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">RAM Size: </span>
                                <span id="specs">
                                    {appState.component.ram.ram_size}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.ram.ram_speed
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">RAM Speed: </span>
                                <span id="specs">
                                    {appState.component.ram.ram_speed}
                                </span>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.ram.dimm_type */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">DIMM Type: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.ram.dimm_type} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.ram.cas_latency */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">CAS Latency: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.ram.cas_latency} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/* </div> */}
                    </td>
                    <PriceLinkRemove
                        item={{ name: "ram", product: appState.component.ram }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default RAM;
