import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
// import LazyLoad from "react-lazyload";
import Page from "../../Page";
import ProductsContext from "../ProductsContext";
import {
    LinkAndAdd,
    SelectorHeading,
    SelectorTopRow,
} from "../SelectorDefault";
import RAMFilter from "./Filter";
import SpecificationBody from "./Specification";
import Loading from "../../LoadingComponent/loading";

function RAM() {
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get("/ram/");
                setAllProducts(response.data);
                setFilteredProducts(response.data);
            } catch (e) {
                console.log("There was a problem.");
            }
        }
        fetchData();
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                allProducts: allProducts,
                filteredProducts: filteredProducts,
                setFilteredProducts: setFilteredProducts,
            }}
        >
            <Page title="RAM">
                <SelectorHeading headingTopic={"RAM"} />



                <div className="container">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header desc-component" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn font-bold" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <i class="ri-information-fill" ></i> RAM
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    A computer needs RAM (Random Access Memory) to store the data and programs you’re running. If you have enough RAM, your computer will work faster, because you won’t have to keep asking your hard drive to find the information you need.
                                    <br />So how much RAM should you get?
                                    <br />Well, that depends. The more RAM you have, the faster your computer will run. But buying a lot of RAM isn’t always a good idea, for two reasons.

                                    <br />First, RAM is pretty expensive.

                                    <br />Second, your computer can only use as much RAM as it has memory slots to hold it. If you have 4 GB of RAM, and you have two slots, your computer will only be able to use 4 GB.

                                    <br />With the advent of virtual machines and the constant need for more efficient software, RAM has become an increasingly important part of PC hardware.

                                    <br />While there are a lot of different specifications to take into account when buying RAM for your PC, there’s only one thing that really matters: how much you can afford.

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <hr />
                <div className="row">
                    <div
                        className="container col-2 pl-4 pr-2 pb-0 d-none d-md-block"
                        style={{ minWidth: "100px" }}
                    >
                        <RAMFilter />
                    </div>
                    <div className="col-md-10 pr-4 text-center">
                        <div className="container">
                            <div className="d-none d-sm-block">
                                <div className="row text-white bg-dark pt-2 pb-2">
                                    <SelectorTopRow />
                                </div>
                            </div>
                            <ul className="list-unstyled">
                                {filteredProducts.length > 0 &&
                                    filteredProducts.map((product) => {
                                        return (
                                            <li key={product.id}>
                                                {/* <LazyLoad height={200} once={true} offset={100}> */}
                                                <Suspense fallback={Loading}>
                                                    <div
                                                        className="row text-dark bg-light py-2 border-bottom border-primary"
                                                    >
                                                        <div className="col-sm-9 px-0">
                                                            <SpecificationBody
                                                                item={product}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 px-0">
                                                            <LinkAndAdd
                                                                item={{
                                                                    product: product,
                                                                    name: "ram",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* </LazyLoad> */}
                                                </Suspense>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {filteredProducts.length === 0 && (
                                <p className="lead text-muted text-center">
                                    <i class="ri-loader-2-fill"></i> Loading...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Page>
        </ProductsContext.Provider>
    );
}

export default RAM;
