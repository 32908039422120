import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove, handleScroll } from "./BuilderDefault";

function Case() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-center"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Case
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.case ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/case" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.case ? (
                    <img
                        src={appState.component.case.image}
                        className="partimg"
                        alt="case"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.case ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">
                                
                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.case.product_id }>
                                    {appState.component.case
                                        ? appState.component.case.name
                                        : null}
                                </a>

                            </p>
                        </div>

                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.case.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.case.brand}
                                </span>
                            </div>

                            <div
                                className={"col px-0".concat(
                                    appState.component.case.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
            
                                <span id="specs_topic">Model: </span>
            
                                <span id="specs">
                                    {appState.component.case.model}
                                </span>
            
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.case.side_panel
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Side Panel: </span>
                                <span id="specs">
                                    {appState.component.case.side_panel}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.case.cabinet_type !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Cabinet Type: </span>
                                <span id="specs">
                                    {appState.component.case.cabinet_type}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.case.color
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Color: </span>
                                <span id="specs">
                                    {appState.component.case.color}
                                </span>
                            </div>
                        </div>
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "case",
                            product: appState.component.case,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default Case;
