import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
// import LazyLoad from "react-lazyload";
import Page from "../../Page";
import ProductsContext from "../ProductsContext";
import {
    LinkAndAdd,
    SelectorHeading,
    SelectorTopRow,
} from "../SelectorDefault";
import GraphicsCardFilter from "./Filter";
import SpecificationBody from "./Specification";
import Loading from "../../LoadingComponent/loading";

function GraphicsCard() {
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get("http://localhost:8000/graphics-card/");

                console.log(response)
                setAllProducts(response.data);
                setFilteredProducts(response.data);
            } catch (e) {

                console.log(e);
            }
        }
        fetchData();
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                allProducts: allProducts,
                filteredProducts: filteredProducts,
                setFilteredProducts: setFilteredProducts,
            }}
        >
            <Page title="GraphicsCard">
                <SelectorHeading headingTopic={"GraphicsCard"} />

                <div className="container">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header desc-component" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn font-bold" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <i class="ri-information-fill" ></i> GraphicsCard
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    A graphics processing unit (GPU) is a specialized electronic circuit designed to rapidly manipulate and alter memory to accelerate the creation of images in a frame buffer intended for output to a display device. GPUs are used in embedded systems, mobile phones, personal computers, workstations, and game consoles.
                                    <br />
                                    <br />The first GPUs came out in the early 1990s. They were a result of the tremendous advances in graphics that had been made in the late 80s and early 90s. The first GPUs were simple. They implemented a small set of instructions that were directly supported by the hardware. The quality of the graphics that came out of these GPUs was terrible.
                                    <br />With the time & advent of new technology modern graphics card are quite powerful in nature.

                                    <br />Graphics cards are the Achilles' heel of a modern gaming PC.  Without one, you can't play games, and the best graphics cards can be prohibitively expensive.  Fortunately, graphics cards are built with a modular design that allows you to choose a card that meets your needs, while balancing price and performance.

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <hr />
                <div className="row">
                    <div
                        className="container col-2 pl-4 pr-2 pb-0 d-none d-md-block"
                        style={{ minWidth: "100px" }}
                    >
                        <GraphicsCardFilter />
                    </div>
                    <div className="col-md-10 pr-4 text-center">
                        <div className="container">
                            <div className="d-none d-sm-block">
                                <div className="row text-white bg-dark pt-2 pb-2">
                                    <SelectorTopRow />
                                </div>
                            </div>
                            <ul className="list-unstyled">
                                {filteredProducts.length > 0 &&
                                    filteredProducts.map((product) => {
                                        return (
                                            <li key={product.id}>
                                                {/* <LazyLoad height={200} once={true} offset={100}> */}
                                                <Suspense fallback={Loading}>
                                                    <div
                                                        className="row text-dark bg-light py-2 border-bottom border-primary"
                                                    >
                                                        <div className="col-sm-9 px-0">
                                                            <SpecificationBody
                                                                item={product}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 px-0">
                                                            <LinkAndAdd
                                                                item={{
                                                                    product: product,
                                                                    name:
                                                                        "graphics_card",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* </LazyLoad> */}
                                                </Suspense>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {filteredProducts.length === 0 && (
                                <p className="lead text-muted text-center">
                                    <i class="ri-loader-2-fill"></i> Loading...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Page>
        </ProductsContext.Provider>
    );
}

export default GraphicsCard;
