import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
// import LazyLoad from "react-lazyload";
import Page from "../../Page";
import ProductsContext from "../ProductsContext";
import {
    LinkAndAdd,
    SelectorHeading,
    SelectorTopRow,
} from "../SelectorDefault";
import CPUCoolerFilter from "./Filter";
import SpecificationBody from "./Specification";
import Loading from "../../LoadingComponent/loading";

function CPUCooler() {
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get("/cpu-cooler/");
                setAllProducts(response.data);
                setFilteredProducts(response.data);
            } catch (e) {
                console.log("There was a problem.");
            }
        }
        fetchData();
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                allProducts: allProducts,
                filteredProducts: filteredProducts,
                setFilteredProducts: setFilteredProducts,
            }}
        >
            <Page title="CPUCooler">
                <SelectorHeading headingTopic={"CPUCooler"} />

                <div className="container">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header desc-component" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn font-bold" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <i class="ri-information-fill" ></i> CPU Cooler
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    The CPU cooler is a tricky component to buy, since all brands are not created equal. And that's because not all CPU coolers are designed to cool just the CPU. Most have a heat sink that radiates heat from the CPU to other components, like the chipset and the RAM.
                                    <br />
                                    Even if you're on a budget, every PC builder knows that sometimes you need to upgrade your components to get better performance. One of the most common upgrades is to replace the stock cooler that comes with your processor. Although stock coolers are the least expensive, they're usually not very powerful, and don't always fit well in a case. That's why high performance coolers are popular upgrades, not only to provide better cooling, but also to fit into smaller spaces.

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <hr />
                <div className="row">
                    <div
                        className="container col-2 pl-4 pr-2 pb-0 d-none d-md-block"
                        style={{ minWidth: "100px" }}
                    >
                        <CPUCoolerFilter />
                    </div>
                    <div className="col-md-10 pr-4 text-center">
                        <div className="container">
                            <div className="d-none d-sm-block">
                                <div className="row text-white bg-dark pt-2 pb-2">
                                    <SelectorTopRow />
                                </div>
                            </div>
                            <ul className="list-unstyled">
                                {filteredProducts.length > 0 &&
                                    filteredProducts.map((product) => {
                                        return (
                                            <li key={product.id}>

                                                <Suspense fallback={Loading}>
                                                    <div

                                                        className="row text-dark bg-light py-2 border-bottom border-primary"
                                                    >
                                                        <div className="col-sm-9 px-0">
                                                            <SpecificationBody
                                                                item={product}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 px-0">
                                                            <LinkAndAdd
                                                                item={{
                                                                    product: product,
                                                                    name: "cpu_cooler",
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </Suspense>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {filteredProducts.length === 0 && (
                                <p className="lead text-muted text-center">
                                    <i class="ri-loader-2-fill"></i> Loading...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Page>
        </ProductsContext.Provider >
    );
}

export default CPUCooler;
