import React, { useContext } from "react";
import StateContext from "../StateContext";

function Compatibility() {
    const appState = useContext(StateContext);
    // const [compatible, setCompatible] = useState(true);
    const isCompatible = () => (
        <>
            <div
                className="row"
                style={{
                    height: 70,
                    background: "#03C03C",
                    color: "white",
                }}
            >
                <h5 className="col align-self-center">
                    &emsp;
                    <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                    &ensp; Components are compatible
                </h5>
            </div>
        </>
    );

    const isNotCompatible = (error) => (
        <>
            <div
                className="row"
                style={{
                    height: 110,
                    background: "#FF0800",
                    color: "white",
                }}
            >
                <div className="col align-self-center">
                    <h4>
                        &emsp;
                        <i class="fa fa-ban" aria-hidden="true"></i>
                        &ensp; Components are incompatible
                    </h4>
                    <p className="col">
                        &emsp;&emsp;&emsp; Revert your last addition or please
                        select a compatible {error}
                    </p>
                </div>
            </div>
        </>
    );

    // Motherboard and Processor
    if (appState.component.motherboard && appState.component.processor) {
        if (
            appState.component.processor.socket_type !==
            appState.component.motherboard.socket
        ) {
            return <>{isNotCompatible("Motherboard and Processor")}</>;
        } else {
            return <>{isCompatible()}</>;
        }
    }
    // Motherboard and Processor


    // GPU and Case
    
    
    if(appState.component.graphics_card && appState.component.case) {
          // console.log(parseFloat( appState.component.graphics_card.length.replace("m", "").replace("m", "") ))
        
          if (
            (parseFloat(appState.component.graphics_card.length.replace("m", "").replace("m", "")) > 0) &&
            appState.component.case.cabinet_type === " MicroATX Mini Tower "
        ) {
            return <>{isNotCompatible("Graphics Card and Case")}</>;
        } else {

            if(
                ((parseFloat( appState.component.graphics_card.length.replace("m", "").replace("m", "") ) * 0.03937008)
                > 12.1
                )&&
                appState.component.case.cabinet_type === " ATX Mid Tower "  
            ) {
                return <>{isNotCompatible("Graphics Card and Case")}</>;
            }
            
            return <>{isCompatible()}</>;
        }
    }
      
    
    // GPU and Case

    // Motherboard and Case
    if (appState.component.motherboard && appState.component.case) {
        if (
            appState.component.motherboard.form_factor.includes("Mini ITX") &&
            appState.component.case.cabinet_type.includes("Mini ITX")
        ) {
            return <>{isCompatible()}</>;
        }
        if (
            appState.component.motherboard.form_factor.includes("Micro") &&
            appState.component.case.cabinet_type.includes("MicroATX")
        ) {
            return <>{isCompatible()}</>;
        }
        if (
            appState.component.motherboard.form_factor.length === 5 &&
            appState.component.case.cabinet_type.includes("Mid")
        ) {
            return <>{isCompatible()}</>;
        }
        if (
            appState.component.motherboard.form_factor.includes("Extended") &&
            appState.component.case.cabinet_type.includes("Full")
        ) {
            return <>{isCompatible()}</>;
        } else {
            return <>{isNotCompatible("Motherboard and Case")}</>;
        }
    }
    // Motherboard and Case

    // Motherboard and RAM
    if (appState.component.motherboard && appState.component.ram) {
        if (
            appState.component.motherboard.memory_slots >=
            parseInt(appState.component.ram.quantity[1]) 

            &&
            appState.component.motherboard.max_memory >=
            parseInt(
                (appState.component.ram.ram_size[1]) + (appState.component.ram.ram_size[2])
            )
        ) {
            
 
            return <>{isCompatible()}</>;
        } else {
            return <>{isNotCompatible("Motherboard and RAM")}</>;
        }
    }
    // Motherboard and RAM
    return <>{isCompatible()}</>;
}

export default Compatibility;
