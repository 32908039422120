
import React, { useState, useEffect } from "react";

const axios = require("axios");

export default function SingleBlog(props) {

  const styleObj1 = {
    backgroundColor: "#33b1ff"
  }
  const [tag, setTag] = useState([]);
  const [auth, setAuth] = useState("");



  async function getAuth() {
    // const response = await axios ({
    // url: process.env.REACT_APP_BACKEND_URL + "/users",
    // method: "GET"
    // }).then((result) => {
    //   setAuth(result) 
    // })	
    await axios({
      url: process.env.REACT_APP_BACKEND_URL + "/users",
      method: "GET"
    }).then((result) => {
      setAuth(result)
    })

  }

  useEffect(() => {
    getAuth();
  }, [auth])

  var auth_string = "";
  function ret_auth(n1) {
    var n;
    n = parseInt(n1)
    //  console.log(auth.data)

    if (auth.data && auth.data.length > 0) {

      for (let index = 0; index < auth.data.length; index++) {
        if (auth.data[index].id === n) {
          auth_string = auth.data[index].username

          return auth_string
        }

      }

      console.log("NOT")
      return "loading"

    }

    return "loading"

  }

  async function getTag() {
    await axios({
      url: process.env.REACT_APP_BACKEND_URL + "/tag",
      method: "GET"
    }).then((result) => {
      setTag(result)
    })

  }

  useEffect(() => {
    getTag();
  }, [tag])

  var tag_string = [];

  function ret_tagname(n1) {
    var n;
    n = n1;

    if (tag.data && tag.data.length > 0) {
      for (let index1 = 0; index1 < n.length; index1++) {

        for (let index = 0; index < tag.data.length; index++) {
          if (tag.data[index].id === n[index1]) {
            tag_string.push(tag.data[index].title + " • ")


          }

        }

      }
      // console.log(tag_string)
      return tag_string
    }

    return "loading"


  }






  var changeDatestyle = (d) => {
    var arr = d.split("-");
    // console.log(arr[0] , arr[1] , arr[2])
    var day;
    switch (parseInt(arr[1], 10)) {
      case 1:
        day = "Jan";
        break;
      case 2:
        day = "Feb";
        break;
      case 3:
        day = "March";
        break;
      case 4:
        day = "April";
        break;
      case 5:
        day = "May";
        break;
      case 6:
        day = "June";
        break
      case 7:
        day = "July";
        break
      case 8:
        day = "Aug";
        break
      case 9:
        day = "Sept";
        break
      case 10:
        day = "Oct";
        break
      case 11:
        day = "Nov";
        break
      case 12:
        day = "Dec";
        break
      default:
        day = "Jan";
        
    }
    return arr[2] + ", " + day + "  " + arr[0]


  }



  return (
    <div className="bg-white font-sans leading-normal tracking-normal">
      <div class="text-center pt-16 md:pt-32">
        <h1 class="font-bold break-normal text-3xl md:text-5xl"> <span style={{ borderBottom: "2px solid gray", borderRadius: "5px" }}> {props.title} </span> </h1>

        <p class="text-sm md:text-base md:p-2"> <span className="text-gray-400"> by</span> <span className="text-black-800 font-bold">{ret_auth(props.author)}</span>  <span class="text-gray-900"> , last updated on </span> <span className="text-indigo-700	">
          {changeDatestyle(`${props.date}`)}  </span>  </p>

      </div>





      <div class="container w-full max-w-6xl mx-auto bg-white bg-cover mt-8 rounded" style={{ backgroundImage: `url(${props.image})`, height: "75vh" }}></div>

      <div class="container max-w-5xl mx-auto -mt-32">

        <div class="mx-0 sm:mx-6">

          <div class="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal" style={{ fontfamily: "Georgia,serif" }}>

            <p style={{ fontSize: "15px" }}>
              <i class="ri-pushpin-fill"></i>&nbsp;

              {/*                     
                    {ret_tagname(`${props.tag}`).map( (t) =>
                      ( <span className="tag-class">{tag}</span>) )  
                    } */}
              <span className="tag-class">{ret_tagname(`${props.tag}`)}</span>


            </p>



            <p class="text-2xl md:text-3xl mb-5">
              <i class="ri-double-quotes-l"></i>   {props.short}
            </p>

            <p class="py-6 text-base" >
              <div style={{ fontfamily: "Lato, sans-serif" }} dangerouslySetInnerHTML={{ __html: props.text }} />
            </p>

            <hr />
            <div class="justify-end content-right">
              <i class="ri-share-fill"></i> &nbsp;
              <a class="inline-block text-white no-underline hover:text-white hover:text-underline text-center h-10 w-10 p-2 md:h-auto md:w-16 md:p-4" href={"whatsapp://send?text=Check out this wonderful blog to build out your next pc, https://thepcbuilders.net/#/blog/" + props.page_link} style={{ backgroundColor: "#25D366" }}>
                <i class="ri-whatsapp-line"></i>
              </a>
              <a class="inline-block text-white no-underline hover:text-white hover:text-underline text-center h-10 w-10 p-2 md:h-auto md:w-16 md:p-4" href={"https://twitter.com/intent/tweet?url=https://thepcbuilders.net/#/blog/" + props.page_link} style={styleObj1}>
                <i class="ri-twitter-line"></i>
              </a>
              <a class="inline-block text-white no-underline hover:text-white hover:text-underline text-center h-10 w-10 p-2 md:h-auto md:w-16 md:p-4" href={"https://www.facebook.com/sharer/sharer.php?u=https://thepcbuilders.net/#/blog/" + props.page_link} style={{ backgroundColor: "#005e99" }}>
                <i class="ri-facebook-line"></i>
              </a>
            </div>
            {/* <button style={{marginTop:"4px",fontSize:"27px"}}><i class="ri-medal-2-line"></i> </button> {props.awards} <span style={{fontSize:"15px"}}>awards</span> */}
          </div>


        </div>
      </div>

    </div>
  )
}
