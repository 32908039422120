import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import Page from "./Page";

import Blog1 from "./Blogs/Blog1";
import Blog2 from "./Blogs/Blog2";

const axios = require("axios");

function Blog() {
	const [state, setState] = useState([]);

	async function getBlog() {
		// let dummyResponse = [];
		// const response = await axios({
		// 	url: process.env.REACT_APP_BACKEND_URL + "/blog",
		// 	method: "GET"
		// }).then((result) => {
		// 	setState(result)
		// })
		const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/blog");
		setState(response.data);
	}

	useEffect(() => {
		getBlog();
	}, [state])

	var returnedArray = []
	var firstarray = []
	// console.log(typeof(state.data))
	if (state.data) {
		returnedArray = state.data.slice(1,);
		firstarray = state.data.slice(0, 1);
		// console.log(firstarray)

	}

	return (
		<Page title="Blog">
			{/* <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">Blog Section</h1>
            </div> */}
			<div className="bg-gray-200 font-sans leading-normal tracking-normal">

				<div className="w-full m-0 p-0 bg-cover bg-bottom blog-body"  >
					<div className="container max-w-4xl mx-auto pt-16 md:pt-32 text-center break-normal">

						<p className="text-white font-extrabold text-3xl md:text-5xl">
							Blog
						</p>
						<p className="text-xl md:text-2xl text-white-500" style={{ color: 'white' }}>PC Assembler</p>
					</div>
				</div>


				<div className="container px-4 md:px-0 max-w-6xl mx-auto -mt-32">

					<div className="mx-0 sm:mx-6">


						<nav className="mt-0 w-full">
							<div className="container mx-auto flex items-center">

								<div className="flex w-1/2 pl-4 text-sm">
									<ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
										{/* <li className="mr-2">
											<a className="inline-block py-2 px-2 text-white no-underline hover:underline" href="#">Recent</a>
										</li> */}
										{/* <li className="mr-2">
								<a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2" href="#">LINK</a>
								</li>
								<li className="mr-2">
								<a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2" >LINK</a>
								</li> */}

									</ul>
								</div>


								<div className="flex w-1/2 justify-end content-center">
									<a className="inline-block text-gray-500 no-underline hover:text-white hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 avatar" data-tippy-content="@twitter_handle" href="https://twitter.com/intent/tweet?url=https://thepcbuilders.net/blog">
										<svg className="fill-current h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path></svg>
									</a>
									<a className="inline-block text-gray-500 no-underline hover:text-white hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 avatar" data-tippy-content="#facebook_id" href="https://www.facebook.com/sharer/sharer.php?u=https://thepcbuilders.net/blog">
										<svg className="fill-current h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"></path></svg>
									</a>
								</div>

							</div>
						</nav>

						<div className="bg-gray-200 w-full text-xl md:text-2xl text-gray-800 leading-normal rounded-t">

							{
								!firstarray ? <h1>Loading</h1> : (
									firstarray.map(post =>
										<Blog1 category={post.blog_category} page_link={`/blog/${post.url_tag}`} author={post.blog_category} blog_time={post.read_time} blog_text={parse(post.short_description)} blog_title={post.title} blog_image={post.thumbnail} blog_date={post.post_date} />
									)
								)
							}


							<div className="flex flex-wrap justify-between pt-12 -mx-6">
								{
									!returnedArray ? null : (
										returnedArray.map(post =>
											<Blog2 page_link={`/blog/${post.url_tag}`} author={post.blog_category} blog_time={post.read_time} blog_text={parse(post.short_description)} blog_title={post.title} blog_image={post.thumbnail} blog_date={post.post_date} />

										)
									)
								}


							</div>

						</div>

					</div>

				</div>
			</div>
		</Page>
	)
}

export default Blog;