import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove, handleScroll } from "./BuilderDefault";

function GraphicsCard() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Graphics Card
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.graphics_card ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/graphics-card" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.graphics_card ? (
                    <img
                        src={appState.component.graphics_card.image}
                        className="partimg"
                        alt="graphics_card"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.graphics_card ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">
                                
                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.graphics_card.product_id }>
                                    {appState.component.graphics_card
                                        ? appState.component.graphics_card.name
                                        : null}
                                </a>

                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.graphics_card.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.graphics_card.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.graphics_card.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.graphics_card.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.graphics_card
                                        .base_clock
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Base Clock: </span>
                                <span id="specs">
                                    {
                                        appState.component.graphics_card
                                            .base_clock
                                    }
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.graphics_card
                                        .clock_speed
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Clock Speed: </span>
                                <span id="specs">
                                    {
                                        appState.component.graphics_card
                                            .clock_speed
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.graphics_card.chipset !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Chipset: </span>
                                <span id="specs">
                                    {appState.component.graphics_card.chipset}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.graphics_card.memory !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Memory: </span>
                                <span id="specs">
                                    {appState.component.graphics_card.memory}
                                </span>
                            </div>
                        </div>

                        {/* <div className="row"> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.graphics_card */}
                        {/*                 .memory_interface */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Memory Interface: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             { */}
                        {/*                 appState.component.graphics_card */}
                        {/*                     .memory_interface */}
                        {/*             } */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.graphics_card */}
                        {/*                 .interface */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Interface: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.graphics_card.interface} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/* </div> */}

                        {/* <div className="row"> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.graphics_card.length !== */}
                        {/*                 "  " */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Length: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.graphics_card.length} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.graphics_card */}
                        {/*                 .frame_sync
                         */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Frame Sync: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             { */}
                        {/*                 appState.component.graphics_card */}
                        {/*                     .frame_sync */}
                        {/*             } */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/* </div> */}
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "graphics_card",
                            product: appState.component.graphics_card,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default GraphicsCard;
