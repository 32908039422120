import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
// import LazyLoad from "react-lazyload";
import Page from "../../Page";
import ProductsContext from "../ProductsContext";
import {
    LinkAndAdd,
    SelectorHeading,
    SelectorTopRow,
} from "../SelectorDefault";
import CaseCoolerFilter from "./Filter";
import SpecificationBody from "./Specification";
import Loading from "../../LoadingComponent/loading";

function CaseCooler() {
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get("/case-cooler/");
                setAllProducts(response.data);
                setFilteredProducts(response.data);
            } catch (e) {
                console.log("There was a problem.");
            }
        }
        fetchData();
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                allProducts: allProducts,
                filteredProducts: filteredProducts,
                setFilteredProducts: setFilteredProducts,
            }}
        >
            <Page title="CaseCooler">
                <SelectorHeading headingTopic={"CaseCooler"} />


                <div className="container">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header desc-component" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn font-bold" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <i class="ri-information-fill" ></i> Case Cooler
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    The computer case is one of the most important parts of a modern computer system. It is a box or cabinet that contains most of the other components. Some people also count the power supply as part of the case, even though it is not usually contained in the same physical enclosure. It provides a home for the motherboard, hard drive(s), optical drives, expansion cards, and more. It also serves as protection for the other components, as the case is the last line of defense against dust, pets, and small children.
                                    <br />
                                    The case is typically external, meaning it is not a part of the computer itself.
                                    <br />
                                    It is always recommended ( and often a good idea ) to get a case cooler for your pc. They come in many variations & sizes , so it is important that you get the right one for your pc. If you have a tower case , then it is a good idea to get a cooler for it.

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <hr />
                <div className="row">
                    <div
                        className="container col-2 pl-4 pr-2 pb-0 d-none d-md-block"
                        style={{ minWidth: "100px" }}
                    >
                        <CaseCoolerFilter />
                    </div>
                    <div className="col-md-10 pr-4 text-center">
                        <div className="container">
                            <div className="d-none d-sm-block">
                                <div className="row text-white bg-dark pt-2 pb-2">
                                    <SelectorTopRow />
                                </div>
                            </div>
                            <ul className="list-unstyled">
                                {filteredProducts.length > 0 &&
                                    filteredProducts.map((product) => {
                                        return (
                                            <li key={product.id}>
                                                {/* <LazyLoad height={200} once={true} offset={100}> */}
                                                <Suspense fallback={Loading}>
                                                    <div
                                                        className="row text-dark bg-light py-2 border-bottom border-primary"
                                                    >
                                                        <div className="col-sm-9 px-0">
                                                            <SpecificationBody
                                                                item={product}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 px-0">
                                                            <LinkAndAdd
                                                                item={{
                                                                    product: product,
                                                                    name: "case_cooler",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Suspense>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {filteredProducts.length === 0 && (
                                <p className="lead text-muted text-center">
                                    <i class="ri-loader-2-fill"></i> Loading...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Page>
        </ProductsContext.Provider>
    );
}

export default CaseCooler;
