import React, { useEffect } from "react";
// import {useContext} from "react";
import Case from "./Builder Components/Case";
import CaseCooler from "./Builder Components/CaseCooler";
import CPUCooler from "./Builder Components/CPUCooler";
import GraphicsCard from "./Builder Components/GraphicsCard";
import Monitor from "./Builder Components/Monitor";
import Motherboard from "./Builder Components/Motherboard";
import PowerSupply from "./Builder Components/PowerSupply";
import Processor from "./Builder Components/Processor";
import RAM from "./Builder Components/RAM";
import Storage from "./Builder Components/Storage";
import Total from "./Builder Components/Total";
import Page from "./Page";
// import StateContext from "../StateContext";
import Compatibility from "./Compatibility";

function Builder() {
    useEffect(() => {
        window.scrollTo(0, localStorage.getItem("scrollPos") || 0);

        return () => localStorage.removeItem("scrollPos");
    }, []);

    // const appState = useContext(StateContext);

    return (
        <Page title="Build Your PC">
            <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">
                    Customize your PC
                </h1>
            </div>
            <div className="container bg-light shadow-lg mb-5 bg-white rounded">
                <Compatibility />
                <table className="table borderless">
                    <thead className="d-none d-md-block thead-dark">
                        <tr className="row">
                            <th className="col-md-1 text-center">Part</th>
                            <th className="col-md-2 text-center">Product</th>
                            <th className="col-md-5 text-center">Title</th>
                            <th className="col-md-1 text-center">Price</th>
                            <th className="col-md-2 text-center">
                                Product Link
                            </th>
                            <th className="col-md-1 text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="row border-bottom">
                            <Processor />
                        </tr>

                        <tr className="row border-bottom">
                            <Motherboard />
                        </tr>

                        <tr className="row border-bottom">
                            <RAM />
                        </tr>

                        <tr className="row border-bottom">
                            <Monitor />
                        </tr>

                        <tr className="row border-bottom">
                            <GraphicsCard />
                        </tr>

                        <tr className="row border-bottom">
                            <Storage />
                        </tr>

                        <tr className="row border-bottom">
                            <PowerSupply />
                        </tr>

                        <tr className="row border-bottom">
                            <Case />
                        </tr>

                        <tr className="row border-bottom">
                            <CaseCooler />
                        </tr>

                        <tr className="row border-bottom">
                            <CPUCooler />
                        </tr>

                        <tr className="row bg-dark text-light">
                            <Total />
                        </tr>
                    </tbody>
                </table>
            </div>
        </Page>
    );
}

export default Builder;
