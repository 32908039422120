import Button from '@mui/material/Button';
import React, { useContext } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import DispatchContext from "../../DispatchContext";

function SelectorTopRow() {
    return (
        <>
            <div className="col-sm-9">
                <div className="row">
                    <div className="col-sm-2">Product</div>
                    <div className="col-sm-8">Title</div>
                    <div className="col-sm-2">Price</div>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="container">
                    <div className="row">
                        <div className="col-8">Link</div>
                        <div className="col-4">Add</div>
                    </div>
                </div>
            </div>
        </>
    );
}

function SelectorHeading(props) {
    return (
        <>
            <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">
                    Choose a {props.headingTopic}
                </h1>
            </div>

        </>
    );
}

function LinkAndAdd(props) {
    const appDispatch = useContext(DispatchContext);
    const history = useNavigate();

    function handleAdd() {
        appDispatch({ type: props.item.name, value: props.item.product });
        history("/builder");
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-8 px-0">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                            window.open(props.item.product.link, "_blank")
                        }
                    >
                        Link to Amazon
                    </Button>
                </div>

                <div className="col-4 px-0">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAdd}
                    >
                        Add
                    </Button>
                </div>
            </div>
        </div>
    );
}

export { SelectorTopRow, SelectorHeading, LinkAndAdd };
