import React, { useState, useEffect } from "react";
const axios = require("axios");

function Blog2(props) {


	const [cat, setCat] = useState("");
	async function getCat() {
		// const response = await axios({
		// 	url: process.env.REACT_APP_BACKEND_URL + "/category",
		// 	method: "GET"
		// }).then((result) => {
		// 	setCat(result)

		// })
		await axios({
			url: process.env.REACT_APP_BACKEND_URL + "/category",
			method: "GET"
		}).then((result) => {
			setCat(result)
		})

	}

	useEffect(() => {
		getCat();
	}, [cat])

	var cat_string = ""
	// console.log(typeof(state.data))

	function ret_categoryname(n1) {
		var n;
		n = parseInt(n1)

		if (cat.data && cat.data.length > 0) {

			for (let index = 0; index < cat.data.length; index++) {
				if (cat.data[index].id === n) {
					cat_string = cat.data[index].title
					console.log("cat_string", cat_string)
					return cat_string
				}

			}

			console.log("NOT")
			return "loading"

		}

		return "loading"


	}

	return (



		<div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
			<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
				<div className="flex flex-wrap no-underline hover:no-underline">
					<img src={props.blog_image} className="h-64 w-full rounded-t pb-6" alt='blog featured' />
					<p className="w-full text-gray-600 text-xs md:text-sm px-6">{props.blog_date}</p>
					<div className="w-full font-bold text-xl text-gray-900 px-6">{props.blog_title}<hr /></div>
					<p className="text-gray-800 font-serif text-base px-6 mb-5">
						<i class="ri-double-quotes-l"></i>  {props.blog_text}... <br /> <a href={props.page_link} className="read-more">Read More</a>
					</p>
				</div>
			</div>
			<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
				<div className="flex items-center justify-between">
					<span className="author-class"  >  <i class="ri-at-line"></i> {ret_categoryname(`${props.category}`)} </span>
					<p className="text-gray-600 text-xs md:text-sm">{props.blog_time}min read</p>
				</div>
			</div>
		</div>



	)
}

export default Blog2;