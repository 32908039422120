import React from 'react'
import Page from "./Page";

export default function Faqs() {
    return (
        <Page title="FAQs">
            <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">FAQs <i style={{ color:"black" , marginTop:"6px"}} class="ri-question-answer-line"></i></h1>
            </div>
            <br/>
            <div className="container bg-light">
                <p className="about_p">
                <div class="container">

                <div id="accordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn faq-title font-bold" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            How ThePCBuilders came into existence?
                            </button>
                        </h5>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                        A few years back, my brother and I were building a gaming PC with parts we had bought off eBay. We had a hard time deciding which parts would work together and which wouldn't, so we decided to put together a simple website so that we could compare parts side-by-side and see the compatibility results. What started as a side project became the foundation of what now is thepcbuilders.net, a simple to use website with compatibility information on over a million parts.  We wanted to create a place where people could easily become educated on how to build their own computers. We hope that you find it useful!
                        </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                            <button class="btn collapsed faq-title" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Are there any websites in the market which helps you to build custom pc?
                            </button>
                        </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                        Building a desktop computer is still a dream for many. Given the intimidating nature of start-up costs, the daunting complexity of part compatibility, and the uncertainty of purchasing the right parts, the prospect of building a computer is enough to keep the average user away. However, there are multiple websites which helps to build custom pc.
            There are many marketplaces offering pre-built custom personal computers, pre-configured to meet your needs. Most business customers prefer to specify their own needs, so there is not much demand for pre-built computers among companies. However, there are many individuals, who are not tech-savvy, who prefer pre-built computers, because they don't want to be bothered with assembling the different components into a computer. Some people are not sure about what components they need, and just want to buy a computer and everything they need.

                        </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                            <button class="btn collapsed faq-title" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            How it is better than pc part picker website?
                            </button>
                        </h5>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div class="card-body">
                        There are many web sites that provide individual PC parts for computer builders. Some of these are reputable, established companies, while others are smaller operations. However, there are two issues with these sites. Firstly, their comparisons are made on a product-by-product basis, and individual parts have different attributes, such as size, performance, and cost (which can be affected by local taxes and shipping).  Secondly, they only compare parts that are actually available, which means that users cannot compare the performance of unavailable products.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFour">
                        <h5 class="mb-0">
                            <button class="btn collapsed faq-title" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            How precise is the PC builders in terms of checking compatibility & latest pricing?
                            </button>
                        </h5>
                        </div>
                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                        <div class="card-body">
                        A new PC is the single most expensive home electronics purchase you can make. This is especially true if you want to build your own machine, since most of the components must be purchased separately.  Precision is of the utmost importance here, since even a seemingly minor incompatibility can ruin your computer. PC component compatibility is much tougher to check than you might think. That's why we've developed a custom builder for making pc parts compatible. With our custom builder you can easily check compatibility with few clicks.
                        </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFive">
                        <h5 class="mb-0">
                            <button class="btn collapsed faq-title" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            Do you charge anything for the custom pc builder
                            </button>
                        </h5>
                        </div>
                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                        <div class="card-body">
                        No we don’t charge anything for the custom builder. The PC Builders is a free to use site & always will be. That’s our promise to you ;)
Though, we would really appreciate if you can share our site among your friends. 

                        </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingSix">
                        <h5 class="mb-0">
                            <button class="btn collapsed faq-title" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            How do I build a custom pc?
                            </button>
                        </h5>
                        </div>
                        <div id="collapseSix" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                        <div class="card-body">
                        The computer hardware market is far too big for any one person to be an expert in it. We all have our preferences, and most of us have a favorite vendor, but no one vendor makes every part that we may need, and no one vendor always has the best price. In the end, the true experts are the people who build computers and the people who sell them.
Building your own computer is a great way to save money while enjoying the satisfaction of creating your own machine. But, unless you have been around computers for a long time, it can be hard to know where to start.  Luckily, most computer components are designed to fit together seamlessly, so even if you don't know much about computers, it is possible to put together a top-notch system.  The first step is learning how to choose the right parts for your build.

                        </div>
                        </div>
                    </div>



                    <div class="card">
                        <div class="card-header" id="headingSeven">
                        <h5 class="mb-0">
                            <button class="btn collapsed faq-title" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            What size PC should I build?
                            </button>
                        </h5>
                        </div>
                        <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                        <div class="card-body">
                        A custom PC is a great way to build the computer you need. There are thousands of parts in the PC market, so the possibilities are endless. The best way to get started is to think about what you're going to use the computer for, and what kind of environment you'll put the PC in. The best PC's for your living room will have a small form factor, so it'll look as good as it performs. The best PC's for your office will have a mid-tower form factor, so it can support multiple graphics cards and more storage. The best PC's for your dorm room will be extremely lightweight, so that you'll be able to carry it with you to classes, or back and forth from your room.
 
 Building a PC is a great way to save money and upgrade your computer. But before you head off to your local computer store, there are some things you should know about PCs, so you don’t overspend on parts you don’t really need. 
 
 

                        </div>
                        </div>
                    </div>

                    
                    </div>
                    
                    </div>
                            
                </p>

            </div>
        </Page>
    )
}
