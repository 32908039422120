import React from 'react'
import Page from "../Page";


export default function AutomaticPage(props) {
    const renderAuthButton = () => {
        if (props.features) {

            return props.features.map((reptile) => <li><i class="ri-focus-2-fill"></i>  {reptile}</li>)
        } else {
            return <li>Loading</li>;
        }
    }

    const renderPicture = () => {
        if (props.picture) {

            // return props.picture.map((reptile) => <img src={reptile} class="img-thumbnail" alt="Responsive image"/>) 
            // return (<img src={props.picture[1]} class="img-thumbnail" alt="Responsive image" />)
            return (<img src={props.picture[1]} class="img-thumbnail" alt="Responsive" />)
        } else {
            return <li>Loading</li>;
        }
    }


    const renderFullfeature = () => {
        if (props.productDetails) {
            return props.productDetails.map((reptile) => <ul><i class="ri-arrow-right-s-line"></i> <span style={{ 'fontFamily': 'monospace', 'fontSize': '17px', 'fontStyle': 'italic' }}> {reptile.name}</span>
                <li>&nbsp;&nbsp;&nbsp;&nbsp; {reptile.value} </li></ul>)
        }
    }

    const renderCat = () => {
        if (props.categories) {
            return props.categories.map((reptile) => <span style={{ 'padding': '3px', 'fontSize': '10px', 'margin': '3px', 'borderRadius': '20px', "backgroundColor": "salmon" }}>
                {reptile}
            </span>)
        }
    }

    return (
        <Page title={props.item_name}>

            <div className="container mx-auto pt-10  text-center break-normal">

                <p className="text-black font-extrabold text-3xl">
                    {props.item_name}
                </p>

            </div>

            <div class="container">

                <div class="row">
                    <div class="col-sm-5">
                        {
                            renderPicture()
                        }
                    </div>
                    <div class="col-sm-7">
                        <span style={{ 'fontStyle': 'italic' }}>{props.item_name}</span>
                        <br />
                        <span style={{ 'fontWeight': 'bold' }} ><i class="ri-user-star-fill"></i> &nbsp;{props.productRating}</span>
                        <br />
                        <span style={{ 'fontWeight': 'bold' }} ><i class="ri-open-arm-fill"></i> &nbsp; Reviewed by &nbsp;{props.countReview} customers</span>
                        <hr />
                        <p>{props.productDescription}</p>
                        <p>
                            {
                                renderCat(props.categories)
                            }
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-5">
                        <span style={{ 'fontWeight': 'bold', 'fontSize': '25px', 'borderBottom': '2px solid blue' }}>Features </span>
                        {
                            renderFullfeature(props.productDetails)
                        }
                    </div>
                    <div class="col-sm-7">
                        <span style={{ 'fontWeight': 'bold', 'fontSize': '25px', 'borderBottom': '2px solid blue' }}> Product Features </span>

                        <ul>

                            {
                                renderAuthButton(props.features)
                            }

                        </ul>
                        <br />

                    </div>
                </div>



            </div>

        </Page>
    )
}
