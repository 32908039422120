import React from "react";

function SpecificationBody(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-2 px-1">
                    <img src={props.item.image} 
                    className="partimg"
                    alt="case"
                    >

                    </img>
                </div>

                <div className="col-sm-8 px-1">
                    <div className="row">
                        <p className="h6 ml-3 text-left">
                            <strong>{props.item.name}</strong>
                        </p>
                    </div>
                    <div className="row">
                        {props.item.brand ? (
                            <div className="col px-0">
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">{props.item.brand}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.model ? (
                            <div className="col px-0">
                                <span id="specs_topic">Model: </span>
                                <span id="specs">{props.item.model}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.fan_rpm ? (
                            <div className="col px-0">
                                <span id="specs_topic">Fan Speed: </span>
                                <span id="specs">{props.item.fan_rpm}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.airflow ? (
                            <div className="col px-0">
                                <span id="specs_topic">Airflow: </span>
                                <span id="specs">{props.item.airflow}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.noise_level ? (
                            <div className="col px-0">
                                <span id="specs_topic">Noise Level: </span>
                                <span id="specs">{props.item.noise_level}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className="col-sm-2 px-0">
                    $ {props.item.price ? props.item.price : "N/A"}
                </div>
            </div>
        </div>
    );
}

export default SpecificationBody;
