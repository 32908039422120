import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove,handleScroll } from "./BuilderDefault";

function Monitor() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Monitor
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.monitor ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/monitor" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.monitor ? (
                    <img
                        src={appState.component.monitor.image}
                        className="partimg"
                        alt="monitor"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.monitor ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">

                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.monitor.product_id }>
                                    {appState.component.monitor
                                        ? appState.component.monitor.name
                                        : null}
                                </a>
    
                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.monitor.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.monitor.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.monitor.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.monitor.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.monitor.size
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Size: </span>
                                <span id="specs">
                                    {appState.component.monitor.size}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.monitor.resolution !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Resolution: </span>
                                <span id="specs">
                                    {appState.component.monitor.resolution}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.monitor.aspect
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Aspect Ratio: </span>
                                <span id="specs">
                                    {appState.component.monitor.aspect}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.monitor.refresh_rate !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Refresh Rate: </span>
                                <span id="specs">
                                    {appState.component.monitor.refresh_rate}
                                </span>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.monitor.panel_type !== */}
                        {/*                 "  " */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Panel Type: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.monitor.panel_type} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.monitor.response_time !== */}
                        {/*                 "  " */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Response Time: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.monitor.response_time} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/* </div> */}
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "monitor",
                            product: appState.component.monitor,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default Monitor;
