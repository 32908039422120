import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import StateContext from "../../StateContext";

// const AMAZON_CART_URL = process.env.REACT_APP_AMAZON_CART_URL + "?" + "AWSAccessKeyId=" +
//     process.env.REACT_APP_AWS_ACCESS_KEY_ID + "&AssociateTag=" +
//     process.env.REACT_APP_ASSOCIATE_TAG

const AMAZON_CART_URL = `${process.env.REACT_APP_AMAZON_CART_URL}
?AWSAccessKeyId=${process.env.REACT_APP_AWS_ACCESS_KEY_ID}
&AssociateTag=${process.env.REACT_APP_ASSOCIATE_TAG}`;

function Total() {
    const appState = useContext(StateContext);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        var TOTAL = 0;
        Object.keys(appState.component).forEach(function (key) {
            if (appState.component[key]) {
                TOTAL += appState.component[key].price;
            }
        });
        setTotal(TOTAL);
    }, [appState]);

    function handleAdd() {
        var cartUrl = "";
        var count = 1;
        Object.keys(appState.component).forEach(function (key) {
            if (appState.component[key]) {
                cartUrl =
                    cartUrl +
                    "&ASIN." +
                    count +
                    "=" +
                    appState.component[key].product_id +
                    "&Quantity." +
                    count +
                    "=1";
                count++;
            }
        });
        cartUrl = AMAZON_CART_URL + cartUrl;
        console.log(process.env.REACT_APP_AMAZON_CART_URL);
        window.open(cartUrl, "_blank");
    }
    return (
        <>
            <th
                className="col-md-8 text-center"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Total
            </th>
            <td className="col-md-1 text-center">$ {total.toFixed(2)}</td>
            <td className="col-md-3 text-center">
                <Button
                    variant="outlined"
                    style={{
                        borderRadius: 5,
                        backgroundColor: "#21b6ae",
                    }}
                    onClick={handleAdd}
                >
                    Add to Amazon Cart
                </Button>
            </td>
        </>
    );
}
export default Total;
