import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove, handleScroll } from "./BuilderDefault";

function Motherboard() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Motherboard
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.motherboard ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/motherboard" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.motherboard ? (
                    <img
                        src={appState.component.motherboard.image}
                        className="partimg"
                        alt="motherboard"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.motherboard ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">
                                
                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.motherboard.product_id }>
                                    {appState.component.motherboard
                                        ? appState.component.motherboard.name
                                        : null}
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.motherboard.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.motherboard.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.motherboard.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.motherboard.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.motherboard.chipset !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Chipset: </span>
                                <span id="specs">
                                    {appState.component.motherboard.chipset}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.motherboard
                                        .form_factor
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Form Factor: </span>
                                <span id="specs">
                                    {appState.component.motherboard.form_factor}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.motherboard.socket !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Socket Type: </span>
                                <span id="specs">
                                    {appState.component.motherboard.socket}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.motherboard
                                        .memory_slots
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Memory Slots: </span>
                                <span id="specs">
                                    {
                                        appState.component.motherboard
                                            .memory_slots
                                    }
                                </span>
                            </div>
                        </div>
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "motherboard",
                            product: appState.component.motherboard,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default Motherboard;
