import React from "react";

function SpecificationBody(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-2 px-1">
                    <img src={props.item.image} className="partimg" alt="processorSpecification"></img>
                </div>

                <div className="col-sm-8 px-1">
                    <div className="row">
                        <p className="h6 ml-3 text-left">
                            <strong > <a style={{ 'color' : 'blue'}} href= {"/components/details/processor/" + props.item.product_id} >{props.item.name}</a></strong>
                        </p>
                    </div>
                    <div className="row">
                        {props.item.brand ? (
                            <div className="col px-0">
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">{props.item.brand}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.model ? (
                            <div className="col px-0">
                                <span id="specs_topic">Model: </span>
                                <span id="specs">{props.item.model}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.architecture ? (
                            <div className="col px-0">
                                <span id="specs_topic">Architecture: </span>
                                <span id="specs">
                                    {props.item.architecture}
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.socket_type ? (
                            <div className="col px-0">
                                <span id="specs_topic">Socket: </span>
                                <span id="specs">{props.item.socket_type}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.memory_type ? (
                            <div className="col px-0">
                                <span id="specs_topic">Memory Type: </span>
                                <span id="specs">{props.item.memory_type}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.cores ? (
                            <div className="col px-0">
                                <span id="specs_topic">Cores: </span>
                                <span id="specs">{props.item.cores}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.threads ? (
                            <div className="col px-0">
                                <span id="specs_topic">Threads: </span>
                                <span id="specs">{props.item.threads}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.turbo_speed ? (
                            <div className="col px-0">
                                <span id="specs_topic">Turbo Speed: </span>
                                <span id="specs">{props.item.turbo_speed}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className="col-sm-2 px-0">
                    $ {props.item.price ? props.item.price : "N/A"}
                </div>
            </div>
        </div>
    );
}

export default SpecificationBody;
