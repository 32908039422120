import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove,handleScroll } from "./BuilderDefault";

function Processor() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Processor
            </th>
            
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.processor ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/processor" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.processor ? (
                    <img
                        src={appState.component.processor.image}
                        className="partimg"
                        alt="processor"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.processor ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">
                                
                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.processor.product_id }>
                                    {appState.component.processor
                                        ? appState.component.processor.name
                                        : null}
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.processor.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.processor.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.processor.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.processor.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.processor.socket_type !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Socket: </span>
                                <span id="specs">
                                    {appState.component.processor.socket_type}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.processor
                                        .architecture
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Architechture: </span>
                                <span id="specs">
                                    {appState.component.processor.architecture}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.processor.core_family !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Core Family: </span>
                                <span id="specs">
                                    {appState.component.processor.core_family}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.processor.threads !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Threads: </span>
                                <span id="specs">
                                    {appState.component.processor.threads}
                                </span>
                            </div>
                        </div>
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "processor",
                            product: appState.component.processor,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default Processor;
