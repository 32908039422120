import React from "react";
import Page from "./Page";

function Contact() {
    const textarea_style = {
        "width": "100%",
        "height": "150px"}

    return (
        <Page title="Contact Us">
            <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">Contact PC Assembler</h1>
            </div>
            
                <div className="container contact-form">
            <div className="contact-image" style={{ textAlign: "center"}}>
                <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact"/>
            </div>

            <div className="container text-center">
            If you want to contact us about anything, then please use the form below. 
            </div>
            <form method="post">
                <h3>Drop Us a Message</h3>
               <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" name="txtName" className="form-control" placeholder="Your Name *"/>
                        </div>
                        <div className="form-group">
                            <input type="text" name="txtEmail" className="form-control" placeholder="Your Email *"/>
                        </div>
                        <div className="form-group">
                            <input type="text" name="txtPhone" className="form-control" placeholder="Your Phone Number *"/>
                        </div>
                        
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <textarea name="txtMsg" className="form-control" placeholder="Your Message *" style={textarea_style}></textarea>
                        </div>
                        <div className="form-group">
                            <input type="submit" name="btnSubmit" className="btnContact" value="Send Message" />
                        </div>
                    </div>
                </div>
            </form>

            <br/>

            {/* <div className="container-fluid text-center">

            We try to reply to all emails within 24 hours. If you get no response please do not use the form again, instead email us direct to [email protected] , as we may not have received your original email. 
            <br/> 
            We are always happy to hear from our visitors, and if there is anything you think we could do to improve our website, or if you just want to tell us how we have helped you, then please get in touch. We are always delighted to hear from our users.
            <br/>
        If you are looking to build a PC, you might want to check out our online builder tool. You can select your preferred parts and it will give you a list of compatible parts to choose from!


            </div> */}
            </div>
        </Page>
    )
}

export default Contact;