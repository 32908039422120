import React from "react";

function Header() {
  return (
    <div>
      {/*     <!--========================== */}
      {/*   Header */}
      {/* ============================--> */}

      <header>
        <nav className="navbar navbar-dark navbar-expand-sm">
          {/* <Link to="/"> */}
          <a className="navbar-brand" href="/">
            {/* <img */}
            {/*   src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/logo_white.png" */}
            {/*   width="30" */}
            {/*   height="30" */}
            {/*   alt="logo" */}
            {/* ></img> */}
            PC Builders
          </a>
          {/* </Link> */}

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-list-2"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbar-list-2"
          >
            <ul className="navbar-nav">
              <li className={"nav-item".concat(window.location.pathname === "/" ? " active" : "")}>
                <a className="nav-link" href="/">
                  Home{" "}
                </a>
              </li>
              <li className={"nav-item".concat(window.location.pathname === "/about" ? " active" : "")}>
                <a className="nav-link" href="/about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blog">
                  Blog
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Sign Up
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link login" href="#">
                  <span className="login">
                    Login
                  </span>
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
      {/* <!-- #header --> */}
    </div>
  );
}

export default Header;
