import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
// import LazyLoad from "react-lazyload";
import Page from "../../Page";
import ProductsContext from "../ProductsContext";
import {
    LinkAndAdd,
    SelectorHeading,
    SelectorTopRow,
} from "../SelectorDefault";
import PowerSupplyFilter from "./Filter";
import SpecificationBody from "./Specification";
import Loading from "../../LoadingComponent/loading";

function PowerSupply() {
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Axios.get("/power-supply/");
                setAllProducts(response.data);
                setFilteredProducts(response.data);
            } catch (e) {
                console.log("There was a problem.");
            }
        }
        fetchData();
    }, []);

    return (
        <ProductsContext.Provider
            value={{
                allProducts: allProducts,
                filteredProducts: filteredProducts,
                setFilteredProducts: setFilteredProducts,
            }}
        >
            <Page title="PowerSupply">
                <SelectorHeading headingTopic={"PowerSupply"} />

                <div className="container">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header desc-component" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn font-bold" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <i class="ri-information-fill" ></i> PowerSupply
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    Most of us don't give our power supply much thought. We pop the power supply into our computer, plug all our devices into it, and turn it on. But we may not be aware that the power supply is the one component we can swap out to get greater efficiency and power for less money.
                                    <br />
                                    You can get away with an average PSU for a few years without it failing on you, but as soon as something does happen, you’ll end up wishing you hadn’t skimped out on a power supply earlier. That’s exactly what happened to one of our users. He read some reviews of cheap PSUs and figured they would be sufficient for his gaming PC build. However, all it took was one power outage for his PC to stop working. He had to spend a couple hundred dollars to replace his PSU, and he’ll be using a high-quality one for years to come.


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div
                        className="container col-2 pl-4 pr-2 pb-0 d-none d-md-block"
                        style={{ minWidth: "100px" }}
                    >
                        <PowerSupplyFilter />
                    </div>
                    <div className="col-md-10 pr-4 text-center">
                        <div className="container">
                            <div className="d-none d-sm-block">
                                <div className="row text-white bg-dark pt-2 pb-2">
                                    <SelectorTopRow />
                                </div>
                            </div>
                            <ul className="list-unstyled">
                                {filteredProducts.length > 0 &&
                                    filteredProducts.map((product) => {
                                        return (
                                            <li key={product.id}>
                                                {/* <LazyLoad height={200} once={true} offset={100}> */}
                                                <Suspense fallback={Loading}>
                                                    <div
                                                        className="row text-dark bg-light py-2 border-bottom border-primary"
                                                    >
                                                        <div className="col-sm-9 px-0">
                                                            <SpecificationBody
                                                                item={product}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 px-0">
                                                            <LinkAndAdd
                                                                item={{
                                                                    product: product,
                                                                    name:
                                                                        "power_supply",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* </LazyLoad> */}
                                                </Suspense>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {filteredProducts.length === 0 && (
                                <p className="lead text-muted text-center">
                                    <i class="ri-loader-2-fill"></i> Loading...
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Page>
        </ProductsContext.Provider>
    );
}

export default PowerSupply;
