import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove, handleScroll } from "./BuilderDefault";

function CPUCooler() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                CPU Cooler
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.cpu_cooler ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/cpu-cooler" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.cpu_cooler ? (
                    <img
                        src={appState.component.cpu_cooler.image}
                        className="partimg"
                        alt="cpu_cooler"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.cpu_cooler ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">

                                <a style={{ 'color': 'blue', 'fontWeight': 'bold' }} href={"/details/" + appState.component.cpu_cooler.product_id}>
                                    {appState.component.cpu_cooler
                                        ? appState.component.cpu_cooler.name
                                        : null}
                                </a>

                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.cpu_cooler.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.cpu_cooler.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.cpu_cooler.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.cpu_cooler.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.cpu_cooler.fan_rpm !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Fan RPM: </span>
                                <span id="specs">
                                    {appState.component.cpu_cooler.fan_rpm}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.cpu_cooler
                                        .noise_level
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Noise Level: </span>
                                <span id="specs">
                                    {appState.component.cpu_cooler.noise_level}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.cpu_cooler.color
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Color: </span>
                                <span id="specs">
                                    {appState.component.cpu_cooler.color}
                                </span>
                            </div>
                        </div>
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "cpu_cooler",
                            product: appState.component.cpu_cooler,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default CPUCooler;
