import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove,handleScroll } from "./BuilderDefault";

function Storage() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Storage
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.storage ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/storage" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.storage ? (
                    <img
                        src={appState.component.storage.image}
                        className="partimg"
                        alt="storage"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.storage ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">
                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.storage.product_id }>
                                    {appState.component.storage
                                        ? appState.component.storage.name
                                        : null}
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.storage.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.storage.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.storage.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.storage.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.storage.type
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Type: </span>
                                <span id="specs">
                                    {appState.component.storage.type}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.storage.interface !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Interface: </span>
                                <span id="specs">
                                    {appState.component.storage.interface}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.storage.capacity
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Capacity: </span>
                                <span id="specs">
                                    {appState.component.storage.capacity}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.storage.form_factor !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Form Factor: </span>
                                <span id="specs">
                                    {appState.component.storage.form_factor}
                                </span>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.storage.rpm */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">RPM: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.storage.rpm} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/*     <div */}
                        {/*         className={"col px-0".concat( */}
                        {/*             appState.component.storage.cache_memory !== */}
                        {/*                 "  " */}
                        {/*                 ? "" */}
                        {/*                 : " d-none" */}
                        {/*         )} */}
                        {/*     > */}
                        {/*         <span id="specs_topic">Cache Memory: </span> */}
                        {/*         <span id="specs"> */}
                        {/*             {appState.component.storage.cache_memory} */}
                        {/*         </span> */}
                        {/*     </div> */}
                        {/* </div> */}
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "storage",
                            product: appState.component.storage,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default Storage;
