import Button from '@mui/material/Button';
import React, { useContext } from "react";
import DispatchContext from "../../DispatchContext";
import StateContext from '../../StateContext';

function PriceLinkRemove(props) {
    const appDispatch = useContext(DispatchContext);
    const appState = useContext(StateContext);
    function handleRemove() {
        appDispatch({ type: props.item.name });
    }

    return (
        <>
            {/* <td className="col-md-1 text-center m-auto">
                ${" "}
                {eval("appState.component." + props.item.name + ".price")
                    ? eval("appState.component." + props.item.name + ".price")
                    : "N/A"}
            </td> */}
            <td className="col-md-1 text-center m-auto">
                ${" "}
                {appState.component[props.item.name]?.price ?? "N/A"}
            </td>
            <td className="col-6 col-md-2 text-center m-auto">
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                        window.open(props.item.product.link, "_blank")
                    }
                >
                    Link to Amazon
                </Button>
            </td>
            <td className="col-6 col-md-1 text-center m-auto">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRemove}
                >
                    {/* Delete */}
                    <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        style={{ fontSize: "1.4em" }}
                    ></i>
                </Button>
            </td>
        </>
    );
}

function handleScroll() {
    localStorage.setItem("scrollPos", window.scrollY);
}

export { PriceLinkRemove, handleScroll };
