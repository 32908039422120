import React from 'react'
import Page from "./Page";

export default function Affiliate() {
    return (
        <Page title="FTC Disclosure for Affiliates">
            <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">FTC Disclosure for Affiliates</h1>
            </div>
            <div className="container bg-light">
                <p className="about_p">
                It is important for us to keep you informed about the money we make through our links. To that end, we are required by the FTC to let you know the following: we make money off of the purchases you make. If you click on a link that we have provided that takes you to an online store that sells a product, and you buy that product, we will receive a commission from the company that runs that store. The amount of commission varies, and it is different for every store. From time to time, we may also use affiliate links for products that we ourselves use and endorse. If you click on the link and buy the product we received some pennies to drink some coffee. We promise you to drink the coffee only while we work on this project to make it better ;)
                <br /><br />
                The PC Builder (https://thepcbuilders.net) is a participant of the Amazon Associates Program, It is a program designed to provide a mean for sites like us to earn advertising fees by linking to their products (i.e Amazon.com, Amazon.co.uk, Amazon.it, Amazon.ca, Amazon.in, and Amazon.com.au) sites.
                <br /><br />
                Pages on this site may include affiliate links to Amazon and its affiliate sites on which the owner of this website will make a referral commission.

                </p>

            </div>
        </Page>
    )
}
