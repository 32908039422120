import React, { useContext } from "react";
import StateContext from "../../StateContext";
import { PriceLinkRemove,handleScroll } from "./BuilderDefault";

function PowerSupply() {
    const appState = useContext(StateContext);
    return (
        <>
            <th
                className="col-md-1 text-left"
                scope="row"
                style={{ display: "flex", alignItems: "center" }}
            >
                Power Supply
            </th>
            <td className="col-md-2 text-center">
                <button
                    className={"btn btn-danger".concat(
                        appState.component.power_supply ? " d-none" : ""
                    )}
                >
                    <a
                        style={{ textDecoration: "none" }}
                        href="/component/power-supply" onClick={handleScroll}
                    >
                        + Component
                    </a>
                </button>
                {appState.component.power_supply ? (
                    <img
                        src={appState.component.power_supply.image}
                        className="partimg"
                        alt="power_supply"
                    ></img>
                ) : (
                    <></>
                )}
            </td>
            {appState.component.power_supply ? (
                <>
                    <td className="col-md-5 text-center">
                        <div className="row">
                            <p className="h6 ml-3 text-left">
                                
                                <a style={{ 'color' : 'blue' , 'fontWeight' : 'bold'}} href={"/details/" + appState.component.power_supply.product_id }>
                                    {appState.component.power_supply
                                        ? appState.component.power_supply.name
                                        : null}
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.power_supply.brand
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">
                                    {appState.component.power_supply.brand}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.power_supply.model
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Model: </span>
                                <span id="specs">
                                    {appState.component.power_supply.model}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.power_supply
                                        .efficiency
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Efficiency: </span>
                                <span id="specs">
                                    {appState.component.power_supply.efficiency}
                                </span>
                            </div>
                            <div
                                className={"col px-0".concat(
                                    appState.component.power_supply.color !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Color: </span>
                                <span id="specs">
                                    {appState.component.power_supply.color}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={"col px-0".concat(
                                    appState.component.power_supply.power !==
                                        "  "
                                        ? ""
                                        : " d-none"
                                )}
                            >
                                <span id="specs_topic">Power: </span>
                                <span id="specs">
                                    {appState.component.power_supply.power}
                                </span>
                            </div>
                        </div>
                    </td>
                    <PriceLinkRemove
                        item={{
                            name: "power_supply",
                            product: appState.component.power_supply,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
export default PowerSupply;
