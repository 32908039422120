import React from "react";
import Page from "./Page";

function About() {
    return (
        <Page title="About">
            <div className="container-fluid bg-info">
                <h1 className="display-4 text-center pb-4 pt-4 text-light">About PC Builders</h1>
            </div>
            <br/>
            <div className="container bg-light">
                <p className="about_p">
                ThePCBuilders is a tool that helps you select the right parts for your computer build. You can select your individual parts or our ThePCBuilders tool can build a full, ready-to-buy computer build for you.<br/>
                 We work with all major components manufacturers to ensure that our database is up-to-date with accurate, real-time pricing. We also have a comprehensive <a style={{ 'color' : 'blue'}} href="/tc">Terms and Conditions</a> that can help you avoid errors.
                </p>

            </div>
        </Page>
    )
}

export default About;