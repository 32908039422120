import React from "react";

function SpecificationBody(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-2 px-1">
                    <img src={props.item.image} className="partimg" alt="itemImage"></img>
                </div>

                <div className="col-sm-8 px-1">
                    <div className="row">
                        <p className="h6 ml-3 text-left">
                            <strong>{props.item.name}</strong>
                        </p>
                    </div>
                    <div className="row">
                        {props.item.brand ? (
                            <div className="col px-0">
                                <span id="specs_topic">Brand: </span>
                                <span id="specs">{props.item.brand}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.model ? (
                            <div className="col px-0">
                                <span id="specs_topic">Model: </span>
                                <span id="specs">{props.item.model}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.type ? (
                            <div className="col px-0">
                                <span id="specs_topic">Type: </span>
                                <span id="specs">{props.item.type}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.interface ? (
                            <div className="col px-0">
                                <span id="specs_topic">Interface: </span>
                                <span id="specs">{props.item.interface}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.form_factor ? (
                            <div className="col px-0">
                                <span id="specs_topic">Form Factor: </span>
                                <span id="specs">{props.item.form_factor}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.cache_memory ? (
                            <div className="col px-0">
                                <span id="specs_topic">Cache Memory: </span>
                                <span id="specs">
                                    {props.item.cache_memory}
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="row">
                        {props.item.capacity ? (
                            <div className="col px-0">
                                <span id="specs_topic">Capacity: </span>
                                <span id="specs">{props.item.capacity}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        {props.item.rpm ? (
                            <div className="col px-0">
                                <span id="specs_topic">RPM: </span>
                                <span id="specs">{props.item.rpm}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className="col-sm-2 px-0">
                    $ {props.item.price ? props.item.price : "N/A"}
                </div>
            </div>
        </div>
    );
}

export default SpecificationBody;
