import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

// import Typography from "@material-ui/core/Typography";

import Typography from '@mui/material/Typography';
import { Link as RouterLink } from "react-router-dom";
import Collapsible from 'react-collapsible';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import ButtonBase from '@mui/material/ButtonBase';

const images = [
  {
    url:
      "https://images.pexels.com/photos/2582935/pexels-photo-2582935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "Processor",
    width: "45%",
    link: "/component/processor",
  },
  {
    url:
      "https://images.pexels.com/photos/4432037/pexels-photo-4432037.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    title: "Motherboard",
    width: "45%",
    link: "/component/motherboard",
  },
  {
    url:
      "https://images.pexels.com/photos/2582928/pexels-photo-2582928.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260&dpr=2",
    title: "RAM",
    width: "30%",
    link: "/component/ram",
  },
  {
    url:
      "https://images.unsplash.com/photo-1593640495390-1ff7c3f60e9b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80",
    title: "Monitor",
    width: "30%",
    link: "/component/monitor",
  },
  {
    url:
      "https://images.unsplash.com/photo-1587134160474-cd3c9a60a34a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    title: "Graphics Card",
    width: "30%",
    link: "/component/graphics-card",
  },
  {
    url:
      "https://images.unsplash.com/photo-1588420833265-28d5eec41d4f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    title: "Storage",
    width: "45%",
    link: "/component/storage",
  },
  {
    url:
      "https://images.unsplash.com/photo-1593152167544-085d3b9c4938?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80",
    title: "Case",
    width: "45%",
    link: "/component/case",
  },
  {
    url:
      "https://cdn.pixabay.com/photo/2018/05/20/11/01/computer-3415505_1280.jpg",
    title: "Power Supply",
    width: "30%",
    link: "/component/power-supply",
  },

  {
    url:
      "https://images.unsplash.com/photo-1555680202-c86f0e12f086?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80",
    title: "Case Cooler",
    width: "30%",
    link: "/component/case-cooler",
  },
  {
    url:
      "https://c0.wallpaperflare.com/preview/36/903/819/asus-cooler-fan-heat-sink.jpg",
    title: "CPU Cooler",
    width: "30%",
    link: "/component/cpu-cooler",
  },
];

const useStyles = createTheme((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
    justifyContent: "center",
  },
  image: {
    position: "relative",
    height: 350,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6
      }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const triggerSiblingExample = () => (
  <div className="Collapsible__custom-sibling">
    <i class="ri-arrow-right-s-fill"></i>
  </div>
);


function Home() {
  // const useStyles = useStyles();
  return (
    <div>
      <ThemeProvider theme={useStyles}>
        {/* <!-- Carousel --> */}
        <div
          className="carousel slide"
          data-ride="carousel"
          id="carouselExampleIndicators"
        >
          <ol className="carousel-indicators">
            <li
              className="active"
              data-slide-to="0"
              data-target="#carouselExampleIndicators"
            ></li>
            <li data-slide-to="1" data-target="#carouselExampleIndicators"></li>
            <li data-slide-to="2" data-target="#carouselExampleIndicators"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              {/* <img alt="First slide" className="d-block w-100" src="https://images.unsplash.com/photo-1603481546164-959efb269a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"> */}
              <img
                alt="First slide"
                src="https://images.unsplash.com/photo-1603481546164-959efb269a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"
              ></img>
              <div className="carousel-caption d-md-block">
                <h5
                  className="animated bounceInRight"
                  style={{ animationDelay: "1s" }}
                >
                  Experience the New Perspective of Building Your Dream PC
                </h5>
                <p
                  className="animated bounceInLeft"
                  style={{ animationDelay: "2s" }}
                >
                  We are here to handle the toughest task of managing the PC
                  compatibility with ease, and providing you with the greatest
                  compatibility.
                </p>
                {/* <!-- <button type="button" className="btn btn-primary btn-info btn-lg  animated bounceInLeft" style="animationDelay: 2s">Large button</button> --> */}

                <p
                  className="animated bounceInRight"
                  style={{ animationDelay: "3s" }}
                >
                  <a href="/builder">Create Now</a>
                </p>

              </div>
            </div>
            <div className="carousel-item">
              {/* <!-- <img alt="Second slide" className="d-block w-100" src="https://images.unsplash.com/photo-1603481588273-2f908a9a7a1b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"> --> */}
              <img
                alt="Second slide"
                src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
              ></img>
              <div className="carousel-caption d-md-block">
                <h5
                  className="animated slideInDown"
                  style={{ animationDelay: "1s" }}
                >
                  Handling Your Toughest PC Parts Compatibility with Ease
                </h5>
                <p className="animated fadeInUp" style={{ animationDelay: "2s" }}>
                  We are here to handle the toughest task of managing the PC
                  compatibility with ease, and providing you with the greatest
                  compatibility.
                </p>
                <p className="animated zoomIn" style={{ animationDelay: "3s" }}>
                  <a href="/builder">Create Now</a>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              {/* <!-- <img alt="Third slide" className="d-block w-100" src="https://images.unsplash.com/photo-1596184661965-27fbeca89723?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"> --> */}
              <img
                alt="Third slide"
                className="d-block w-100"
                src="https://images.unsplash.com/photo-1596184661965-27fbeca89723?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"
              ></img>
              <div className="carousel-caption d-md-block">
                <h5 className="animated zoomIn" style={{ animationDelay: "1s" }}>
                  Actually, We Breathe Technology, and We Love Caring Tech
                </h5>
                <p
                  className="animated fadeInLeft"
                  style={{ animationDelay: "2s" }}
                >
                  We're best at managing the PC related technologies and its
                  compatibilities and we're here to turn your imagination into
                  reality.
                </p>
                <p className="animated zoomIn" style={{ animationDelay: "3s" }}>
                  <a href="/builder">Create Now</a>
                </p>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            data-slide="prev"
            href="#carouselExampleIndicators"
            role="button"
          >
            <span
              aria-hidden="true"
              className="carousel-control-prev-icon"
            ></span>{" "}
            <span className="sr-only">Previous</span>
          </a>{" "}
          <a
            className="carousel-control-next"
            data-slide="next"
            href="#carouselExampleIndicators"
            role="button"
          >
            <span
              aria-hidden="true"
              className="carousel-control-next-icon"
            ></span>{" "}
            <span className="sr-only">Next</span>
          </a>
        </div>

        {/* <!-- Carousel --> */}



        {/* <!-- Explore --> */}

        <section className="container-fluid demo">
          <div className="title">Lorem Ipsum</div>
          <p className="text-center px-lg-5 mb-0">
            Eiusmod occaecat sunt nulla dolor qui exercitation in nostrud aliqua.
            Sint labore labore proident incididunt voluptate mollit dolore
            voluptate consequat duis elit quis cillum cillum do consectetur aute
            esse. Non aute qui commodo fugiat voluptate anim ut officia
            adipisicing sed sed.
          </p>

          <div className="row pre-builts">
            <div className="col-12 col-md-4" >
              <div className="absolute">
                <div className="btn-middle">
                  <a className="btn btn-primary" href="/builder">
                    Build
                  </a>
                </div>

                <img
                  className="img-fluid image lazy loaded"
                  alt="gaming pc build, gaming pc builder, pcpartpicker build, custom pc builder"
                  data-ll-status="loaded"
                  src="https://images.unsplash.com/photo-1538481199705-c710c4e965fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1132&q=80"
                ></img>

                <span className="build-title">
                  Your next big PC.
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="absolute">
                <div className="btn-middle">
                  <a className="btn btn-primary" href="/builder">
                    Build
                  </a>
                </div>

                <img
                  className="img-fluid image lazy loaded"
                  alt="pc builds, pre-built pc, custom pc builder"
                  data-ll-status="loaded"
                  src="https://images.unsplash.com/photo-1583165326883-259e2e59d239?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                ></img>

                <span className="build-title">
                  Your next big PC.
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">

              <div className="absolute">
                <div className="btn-middle">
                  <a className="btn btn-primary" href="/builder">
                    Build
                  </a>
                </div>

                <img
                  className="img-fluid image lazy loaded"
                  alt="all in one pc builds, pre-built aio pc, custom pc builder"
                  data-ll-status="loaded"
                  src="https://images.unsplash.com/photo-1587202372616-b43abea06c2a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80"
                ></img>
                <span className="build-title">
                  Your next big PC.
                </span>
              </div>
            </div>
          </div>

          <div className="pre-button">
            <a className="btn btn-custom" href="/builder">
              Explore
            </a>
          </div>
        </section>

        {/* <!-- Explore --> */}

        {/* features */}


        <div class="container-fluid bg-dark">

          <div class="container">
            <br />
            <div class="row">

              <div class="col-sm">
                <div className="row">
                  <div className="col-2">
                    <i class="ri-shield-keyhole-fill feature-icon"></i>
                  </div>
                  <div className="col">
                    <h3 className="feature-caption">
                      Assurity with PC Compatibility
                    </h3>
                    <p className="feature-para">
                      With a few clicks you can find out what hardware will fit, what parts need to be upgraded or downgraded, and how much you'll end up spending. There are a lot of tools out there that will do this, but The PC Builders will give you the best value.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div className="row">
                  <div className="col-2">
                    <i class="ri-slideshow-fill feature-icon"></i>
                  </div>
                  <div className="col">
                    <h3 className="feature-caption">
                      PC Build Showcase
                    </h3>
                    <p className="feature-para">
                      have created your build, how do you share it with the world? You can post a picture or video of your build to many sites. You can use your own custom created rig link to share the love with the world.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div className="row">
                  <div className="col-2">
                    <i class="ri-stack-fill feature-icon"></i>
                  </div>
                  <div className="col">
                    <h3 className="feature-caption">
                      PC parts availability
                    </h3>
                    <p className="feature-para">
                      Components aren't something you can buy at a retail store. You have to buy them from a store that specializes in selling individual components for computers. For example, you can't buy the graphics card you want from one store and a motherboard from another, because they usually don't sell the parts necessary to assemble a computer. What's more, you can't even find all the parts you want in one location.
                      <br />At The PC Builders, we provide different merchant option which are ready to sell your required piece on Amazon.

                    </p>
                  </div>
                </div>
              </div>

            </div>

            <br />

            <div class="row">

              <div class="col-sm">
                <div className="row">
                  <div className="col-2">
                    <i class="ri-history-fill feature-icon"></i>
                  </div>
                  <div className="col">
                    <h3 className="feature-caption">
                      Price History
                    </h3>
                    <p className="feature-para">
                      As a technology blog that specializes in building personal computers, we often get emails requesting a price history of a given computer part. To make it a bit easier, we are going to look at the performance of the parts inside your pc. Since all the parts are directly related to each other, we can use the products current market price to predict how much your pc is going to cost you to build. We use a product from our current top sellers list.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div className="row">
                  <div className="col-2">
                    <i class="ri-git-branch-fill feature-icon"></i>
                  </div>
                  <div className="col">
                    <h3 className="feature-caption">
                      Clearance with Dependencies
                    </h3>
                    <p className="feature-para">
                      Do you have a PC build that you'd like to order from us, but the parts in it are not clearanced? Well, we'd be happy to provide clearance with the parts, but you may need to provide a picture of the build you'd like to order, and the parts in it, so that we can make sure they're all compatible. We'd be happy to take the time to talk about this with you, and help you make sure you're best set up for success.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div className="row">
                  <div className="col-2">
                    <i class="ri-stackshare-line feature-icon"></i>
                  </div>
                  <div className="col">
                    <h3 className="feature-caption">
                      Share the love
                    </h3>
                    <p className="feature-para">
                      So you recently did a build using one of our custom builders and you're wondering if you can link to it to show off your work. You are right to be unsure, as we understand this is a concern of many people. We don't want your custom build to be seen as a sponsored link for the components you used. You can share your custom build link after you have selected the components that make up your custom build.
                    </p>
                  </div>
                </div>
              </div>

            </div>


            <br />

          </div>

        </div>

        {/* <-- feature --> */}



        {/* <!-- What we do --> */}

        <div className="jumbotron jumbotron-fluid bg-dark">
          <div className="container">
            <h1 className="display-4 text-white text-center font-weight-bold">
              <span className="border-bottom">What we do</span></h1>
            <div className="container text-success">
              <div className="row text-center">
                <div className="col-sm col-para">

                  <div className="icondiv">
                    <i class="ri-lightbulb-line"></i>
                  </div>

                  First, we gather the latest technology news from the world of PC hardware and software, from the bleeding edge to the point of obsolescence. We also cross check the data from official PC part manufacturers.

                </div>
                <div className="col-sm col-para">
                  <div className="icondiv">
                    <i class="ri-medal-2-fill"></i>
                  </div>
                  Then, we analyze all the data using our advanced PC part compatibility checking algorithm.  We make sure that our system is updated frequently to make sure that you don’t miss a thing!

                </div>
                <div className="col-sm col-para">
                  <div className="icondiv">
                    <i class="ri-ghost-2-line"></i>
                  </div>

                  We provide a simple pc builder for you. You can choose the pc part you need. If they are incompatible then we will show you a warning message. We check hundreds of factors if your chosen parts are compatible with each other or not. So that you can buy pc parts without having to worry about their compatibility

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- What we do --> */}

        {/* <!-- Component Grid --> */}

        <p className="text-center" style={{ 'color': '#00010f', 'fontFamily': 'Lucida Sans Regular', 'fontSize': '30px', 'fontWeight': '700' }}>
          Build your next big Machine
        </p>

        <div className={useStyles.root}>

          {images.map((image) => (
            <ButtonBase
              focusRipple
              key={image.title}
              className={useStyles.image}
              focusVisibleClassName={useStyles.focusVisible}
              style={{
                width: image.width,
              }}
              component={RouterLink}
              to={image.link}
              target="_blank"
            >
              <span
                className={useStyles.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <span className={useStyles.imageBackdrop} />
              <span className={useStyles.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={useStyles.imageTitle}
                >
                  {image.title}
                  <span className={useStyles.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>
        <br />
        <div className="jumbotron jumbotron-fluid bg-dark">
          <div className="container">
            <h2 className="text-center font-weight-bold" style={{ 'color': 'whitesmoke' }}>
              Getting Started
            </h2>
            <br />
            <div class="container">

              <div class="row">

                <div class="col-sm getting-started-box">
                  <Collapsible transitionTime={400} triggerSibling={triggerSiblingExample} triggerOpenedClassName="trigger-str-class" contentInnerClassName="inner-gs-class" openedClassName="collapse-open" className="collapse-accordion" trigger="How hard it to build a pc?">
                    <p className="collapse-content">
                      Building your own computer can save a lot of money, but it can also be a lot of work.  While the seemingly endless customizations and options might be daunting at first, the process isn't as complicated as it seems. Building your own PC used to be a daunting task for the uninitiated, but with the popularity of component compatibility checkers, this process has become much easier over the years. Before you know it, you'll be building your own PC with the help of our custom builder.
                    </p>

                  </Collapsible>
                </div>

                <div class="col-sm getting-started-box">
                  <Collapsible transitionTime={400} triggerSibling={triggerSiblingExample} triggerOpenedClassName="trigger-str-class" contentInnerClassName="inner-gs-class" openedClassName="collapse-open" className="collapse-accordion" trigger="How our PC Part Compatibility system works?">
                    <p className="collapse-content">
                      The "pc part compatibility system" that we developed here at PC Part Builder helps us have the best selection of compatible computer parts. It allows us to match other sites' parts to our own selection and recommends compatible parts to a customer if they already have a part in mind. This system is based on a customer having a preferred part, which we can then match to another compatible part.


                    </p>

                  </Collapsible>
                </div>

              </div>

              <div class="row">

                <div class="col-sm getting-started-box">
                  <Collapsible transitionTime={400} triggerSibling={triggerSiblingExample} triggerOpenedClassName="trigger-str-class" contentInnerClassName="inner-gs-class" openedClassName="collapse-open" className="collapse-accordion" trigger="Why You Should Trust Us?">
                    <p className="collapse-content">

                      We live in a world where we can buy pretty much anything we desire, whether it's a new smartphone or a new car.  If you want to save a buck or two, you can go to a huge online retailer such as Amazon and do the shopping yourself.  However, if you happen to be a savvy tech-savvy person who wants to get the most bang for your buck when it comes to building a computer, the best place to go is "The PC Builders."
                    </p>

                  </Collapsible>
                </div>

                <div class="col-sm getting-started-box">
                  <Collapsible transitionTime={400} triggerSibling={triggerSiblingExample} triggerOpenedClassName="trigger-str-class" contentInnerClassName="inner-gs-class" openedClassName="collapse-open" className="collapse-accordion" trigger="I am facing difficulties in pc building. Can I get help?">
                    <p className="collapse-content">
                      If you’re new to PC building, it can be hard to know where to start. Where do you find the components? Which parts should you buy? Will they all fit together? No worries. We’ve got you covered. With our custom pc builder you will be able to build your pc with utmost ease.


                    </p>

                  </Collapsible>
                </div>

              </div>

            </div>


          </div>
        </div>

        {/* <!-- Component Grid --> */}
      </ThemeProvider>;
    </div>
  );
}

export default Home;
