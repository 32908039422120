import Axios from "axios";

import React, { useReducer } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import dispatchContext from "./DispatchContext";
import stateContext from "./StateContext";
import About from "./components/About";
import Affiliate from "./components/Affiliate";
import AutomaticPage from "./components/AutoResults/Automatic_Page";
import Blog from "./components/Blog";
import SingleBlog from "./components/Blogs/SingleBlog";
import Builder from "./components/Builder";
import Contact from "./components/Contact";
import Faqs from "./components/Faqs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import CPUCooler from "./components/PCParts/CPUCooler/CPUCooler";
import Case from "./components/PCParts/Case/Case";
import CaseCooler from "./components/PCParts/CaseCooler/CaseCooler";
import GraphicsCard from "./components/PCParts/GraphicsCard/GraphicsCard";
import Monitor from "./components/PCParts/Monitor/Monitor";
import Motherboard from "./components/PCParts/Motherboard/Motherboard";
import PowerSupply from "./components/PCParts/PowerSupply/PowerSupply";
import Processor from "./components/PCParts/Processor/Processor";
import RAM from "./components/PCParts/RAM/RAM";
import Storage from "./components/PCParts/Storage/Storage";

// const axios = require("axios");

Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

function App() {
  const initialState = {
    component: {
      motherboard: JSON.parse(localStorage.getItem("motherboard")),
      processor: JSON.parse(localStorage.getItem("processor")),
      ram: JSON.parse(localStorage.getItem("ram")),
      monitor: JSON.parse(localStorage.getItem("monitor")),
      graphics_card: JSON.parse(localStorage.getItem("graphics_card")),
      storage: JSON.parse(localStorage.getItem("storage")),
      power_supply: JSON.parse(localStorage.getItem("power_supply")),
      case: JSON.parse(localStorage.getItem("case")),
      case_cooler: JSON.parse(localStorage.getItem("case_cooler")),
      cpu_cooler: JSON.parse(localStorage.getItem("cpu_cooler")),
    },
  };

  function stateReducer(state, action) {
    switch (action.type) {
      case "motherboard":
        if (state.component.motherboard) {
          localStorage.removeItem("motherboard");
          return {
            ...state,
            component: {
              ...state.component,
              motherboard: initialState.component.motherboard,
            },
          };
        } else {
          localStorage.setItem("motherboard", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, motherboard: action.value },
          };
        }
      case "processor":
        if (state.component.processor) {
          localStorage.removeItem("processor");
          return {
            ...state,
            component: {
              ...state.component,
              processor: initialState.component.processor,
            },
          };
        } else {
          localStorage.setItem("processor", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, processor: action.value },
          };
        }
      case "ram":
        if (state.component.ram) {
          localStorage.removeItem("ram");
          return {
            ...state,
            component: { ...state.component, ram: initialState.component.ram },
          };
        } else {
          localStorage.setItem("ram", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, ram: action.value },
          };
        }
      case "monitor":
        if (state.component.monitor) {
          localStorage.removeItem("monitor");
          return {
            ...state,
            component: {
              ...state.component,
              monitor: initialState.component.monitor,
            },
          };
        } else {
          localStorage.setItem("monitor", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, monitor: action.value },
          };
        }
      case "graphics_card":
        if (state.component.graphics_card) {
          localStorage.removeItem("graphics_card");
          return {
            ...state,
            component: {
              ...state.component,
              graphics_card: initialState.component.graphics_card,
            },
          };
        } else {
          localStorage.setItem("graphics_card", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, graphics_card: action.value },
          };
        }
      case "storage":
        if (state.component.storage) {
          localStorage.removeItem("storage");
          return {
            ...state,
            component: {
              ...state.component,
              storage: initialState.component.storage,
            },
          };
        } else {
          localStorage.setItem("storage", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, storage: action.value },
          };
        }
      case "power_supply":
        if (state.component.power_supply) {
          localStorage.removeItem("power_supply");
          return {
            ...state,
            component: {
              ...state.component,
              power_supply: initialState.component.power_supply,
            },
          };
        } else {
          localStorage.setItem("power_supply", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, power_supply: action.value },
          };
        }
      case "case":
        if (state.component.case) {
          localStorage.removeItem("case");
          return {
            ...state,
            component: {
              ...state.component,
              case: initialState.component.case,
            },
          };
        } else {
          localStorage.setItem("case", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, case: action.value },
          };
        }
      case "case_cooler":
        if (state.component.case_cooler) {
          localStorage.removeItem("case_cooler");
          return {
            ...state,
            component: {
              ...state.component,
              case_cooler: initialState.component.case_cooler,
            },
          };
        } else {
          localStorage.setItem("case_cooler", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, case_cooler: action.value },
          };
        }
      case "cpu_cooler":
        if (state.component.cpu_cooler) {
          localStorage.removeItem("cpu_cooler");
          return {
            ...state,
            component: {
              ...state.component,
              cpu_cooler: initialState.component.cpu_cooler,
            },
          };
        } else {
          localStorage.setItem("cpu_cooler", JSON.stringify(action.value));
          return {
            ...state,
            component: { ...state.component, cpu_cooler: action.value },
          };
        }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(stateReducer, initialState);
  // const [Blogstate, setBlog] = useState([]);
  const Blogstate = [];

  // const [Autostate, setAuto] = useState([]);
  const Autostate = [];

  return (
    <div className="App">
      <dispatchContext.Provider value={dispatch}>
        <stateContext.Provider value={state}>
          <Router>
            <Header />

            <Routes>
              <Route path='/blog/:id' render={(props) => {
                // console.log(props)

                // function getDetails(post, props) {
                //   if (post.url_tag === props.match.params.id) {
                //     setBlog(post)
                //   }
                // }

                // async function getBlog() {
                //   const response = await axios({
                //     url: process.env.REACT_APP_BACKEND_URL + "/blog",
                //     method: "GET"
                //   })
                //   // console.log(response)	

                //   response.data.map(post =>
                //     getDetails(post, props)
                //   )
                // }
                // getBlog()

                return (<SingleBlog tag={Blogstate.blog_tag} page_link={Blogstate.url_tag} short={Blogstate.short_description} text={Blogstate.body} image={Blogstate.thumbnail} author={Blogstate.author} title={Blogstate.title} date={Blogstate.post_date} />
                )
              }} />

              <Route path="/details/:p_id" render={(props) => {

                // async function getAuto() {
                //   const response = await axios({
                //     url: process.env.REACT_APP_BACKEND_URL + "/product_info/" + props.match.params.p_id,
                //     method: "GET"
                //   }).then((response) => {
                //     console.log(response)
                //     setAuto(response.data)
                //   })

                // }

                // getAuto()

                return (<AutomaticPage picture={Autostate.imageUrlList} item_name={Autostate.productTitle} productRating={Autostate.productRating}
                  countReview={Autostate.countReview} productDescription={Autostate.productDescription}
                  features={Autostate.features} productDetails={Autostate.productDetails}
                  categories={Autostate.categories}
                />
                )
              }}>


              </Route>

              <Route path="/faqs" element={<Faqs />} >
              </Route>
              <Route path="/affiliate" element={<Affiliate />} >
              </Route>
              <Route path="/blog" element={<Blog />} >
              </Route>
              <Route path="/about" element={<About />} >
              </Route>
              <Route path="/builder" element={<Builder />} >
              </Route>
              <Route path="/contact" element={<Contact />} >
              </Route>
              <Route path="/component/processor" element={<Processor />} >
              </Route>
              <Route path="/component/motherboard" element={<Motherboard />} >
              </Route>
              <Route path="/component/ram" element={<RAM />} >
              </Route>
              <Route path="/component/storage" element={<Storage />} >
              </Route>
              <Route path="/component/graphics-card" element={<GraphicsCard />} >
              </Route>
              <Route path="/component/monitor" element={<Monitor />} >
              </Route>
              <Route path="/component/power-supply" element={<PowerSupply />} >
              </Route>
              <Route path="/component/case" element={<Case />} >
              </Route>
              <Route path="/component/case-cooler" element={<CaseCooler />} >
              </Route>
              <Route path="/component/cpu-cooler" element={<CPUCooler />} >
              </Route>
              <Route path="/" element={<Home />} >
              </Route>

            </Routes>
            <Footer />
          </Router>
        </stateContext.Provider>
      </dispatchContext.Provider>
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js"></script>
      {/* <!--         <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js"> */}
      {/*         </script> */}
      {/*  --> */}
      {/*      <!-- <div style="height:100vh; background:black">   </div> --> */}
    </div>
  );
}

export default App;
