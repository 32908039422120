import React, { useContext, useEffect, useReducer } from "react";
import ProductsContext from "../ProductsContext";

const filter_bar = {
    "cursor": "pointer",
    "borderRadius": "8px"
}
const manufacturers = new Set()
const brands = new Set()

function MotherboardFilter() {

    const { allProducts, filteredProducts, setFilteredProducts } = useContext(ProductsContext);
    console.log(filteredProducts)
    const initialState = {
        manufacturer: [null],
        brand: [null]
    }

    function reducer(state, action) {
        switch (action.type) {
            case 'ADD':
                switch (action.payload[1]) {
                    case "manufacturer":
                        return {
                            ...state, manufacturer: [...state.manufacturer, action.payload[0]]
                        }
                    case "brand":
                        return {
                            ...state, brand: [...state.brand, action.payload[0]]
                        }
                    default:
                        return state
                }

            case 'REMOVE':
                switch (action.payload[1]) {
                    case "manufacturer":
                        var filteredResult = state.manufacturer.filter(m => m !== action.payload[0])
                        if (filteredResult.length === 0)
                            return {
                                ...state, manufacturer: initialState.manufacturer
                            }
                        else
                            return {
                                ...state, manufacturer: filteredResult
                            }
                    case "brand":
                        var filteredResult2 = state.brand.filter(m => m !== action.payload[0])
                        if (filteredResult2.length === 0)
                            return {
                                ...state, brand: initialState.brand
                            }
                        else
                            return {
                                ...state, brand: filteredResult2
                            }
                    default:
                        return state
                }
            default:
                return state

        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    function handleFilter(e) {
        const filter_group = e.target.name
        const filter = e.target.id.substring(e.target.id.indexOf(" ") + 1)
        if (e.target.checked) {
            dispatch({ type: 'ADD', payload: [filter, filter_group] })
        }
        else {
            dispatch({ type: "REMOVE", payload: [filter, filter_group] })
        }
    }

    useEffect(() => {
        var count = 0, length = 0
        for (var key in state) {
            length += state[key].length    // This loop is to compare state with initialstate
            count++
        }
        if (length === count) {
            setFilteredProducts(allProducts)
            return
        }

        setFilteredProducts(
            allProducts.filter(
                item => {
                    return state.brand.includes(item.brand) || state.manufacturer.includes(item.manufacturer)
                }
            ))
    }, [state, allProducts, setFilteredProducts])

    useEffect(() => {
        allProducts.foreach(product => {
            if (product.manufacturer != null)
                manufacturers.add(product.manufacturer)
            if (product.brand != null)
                brands.add(product.brand)
        })
    }, [allProducts])

    return (
        <>
            <div className="container bg-dark text-center text-white py-1">
                <div className="row">
                    <div className="col" >
                        Filters
                    </div>
                </div>
            </div>

            <div className="container manufacturer-class text-center text-white py-1 mt-3" style={filter_bar} data-toggle="collapse" data-target="#manufact" aria-expanded="false" aria-controls="collapseExample">
                <div className="row">
                    <div className="col" >
                        Manufacturer
                    </div>
                </div>
            </div>

            <div className="collapse" id="manufact">
                <ul className="list-group">
                    {
                        [...manufacturers].map(m => {
                            return (

                                <li key={m} className="list-group-item rounded-0">
                                    <div className="custom-control custom-checkbox">
                                        <input name="manufacturer" className="custom-control-input" id={"manufacturer " + m} type="checkbox" onClick={(e) => handleFilter(e)} />
                                        <label className="cursor-pointer font-italic d-block custom-control-label" htmlFor={"manufacturer " + m}>{m}</label>
                                    </div>
                                </li>

                            )

                        })
                    }
                </ul>

            </div>

            <div className="container manufacturer-class text-center text-white py-1 mt-3" style={filter_bar} data-toggle="collapse" data-target="#socket" aria-expanded="false" aria-controls="collapseExample">
                <div className="row">
                    <div className="col" >
                        Brand
                    </div>
                </div>
            </div>

            <div className="collapse" id="socket">
                <ul className="list-group">
                    {
                        [...brands].map(m => {
                            return (

                                <li key={m} className="list-group-item rounded-0">
                                    <div className="custom-control custom-checkbox">
                                        <input name="brand" className="custom-control-input" id={"brand " + m} type="checkbox" onClick={(e) => handleFilter(e)} />
                                        <label className="cursor-pointer font-italic d-block custom-control-label" htmlFor={"brand " + m}>{m}</label>
                                    </div>
                                </li>

                            )

                        })
                    }
                </ul>

            </div>
        </>
    )
}

export default MotherboardFilter;