import React from 'react';

const Loading = () => {
    const loadingContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
    };

    const spinnerStyle = {
        border: '16px solid #f3f3f3',
        borderTop: '16px solid #3498db',
        borderRadius: '50%',
        width: '120px',
        height: '120px',
        animation: 'spin 2s linear infinite'
    };

    return (
        <div style={loadingContainerStyle}>
            <div style={spinnerStyle}></div>
            <h2>Loading...</h2>
        </div>
    );
};

export default Loading;